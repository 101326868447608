import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import WarrantyMixin from "@/core/lib/warranty/warranty.mixin";
import ProductMixin from "@/core/plugins/product-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ManageBrand from "@/view/pages/partials/Manage-Product-Brand.vue";
import ManageManufacturer from "@/view/pages/partials/Manage-Product-Manufacturer.vue";
import ManageCategory from "@/view/pages/partials/Manage-Product-Category.vue";
import ManageUnit from "@/view/pages/partials/Manage-Product-Unit.vue";
import { QUERY, DELETE, UPDATE_ERROR, CLEAR_ERROR } from "@/core/services/store/request.module";
import { map, compact } from "lodash";
export default {
  name: "product-create",
  mixins: [CommonMixin, WarrantyMixin, ProductMixin, ValidationMixin],
  props: {
    productId: {
      type: [Number, String],
      default: null
    },
    barcodeData: {
      type: String,
      default: null
    },
    updateMode: {
      type: Boolean,
      default: false
    },
    productCreateData: {
      type: Object,
      default: function _default() {
        return new Object();
      }
    }
  },
  beforeUpdate: function beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  components: {
    FileTemplate: FileTemplate,
    ManageBrand: ManageBrand,
    ManageManufacturer: ManageManufacturer,
    ManageCategory: ManageCategory,
    ManageUnit: ManageUnit
  },
  data: function data() {
    return {
      timeoutLimit: 500,
      timeout: null,
      brandList: new Array(),
      categoryList: new Array(),
      manufacturerList: new Array(),
      supplierList: new Array(),
      taxList: new Array(),
      taxExemptionList: new Array(),
      unitList: new Array(),
      barcode: null,
      manageBrandDialog: false,
      manageManufacturerDialog: false,
      manageCategoryDialog: false,
      manageUnitDialog: false,
      warrantyActive: false,
      productCreate: {
        name: null,
        reference: null,
        sku: null,
        upc: null,
        ean: null,
        isbn: null,
        serial_number: null,
        part_number: null,
        hsn_code: null,
        description: null,
        directory: null,
        supplier: null,
        brand: null,
        manufacturer: null,
        category: null,
        unit: null,
        tax: null,
        tax_exemption: null,
        accounting: "none",
        product_type: "goods",
        purchase_cost: null,
        selling_cost: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        taxable: false,
        sellable: true,
        confirmed: false,
        track_batch: false,
        manage_stock: true,
        reorder_level: null,
        initial_stock: null,
        charges: null,
        images: [],
        product_images: [],
        warranty: [{
          id: null,
          value: null,
          field: null
        }]
      },
      fieldDescriptions: [{
        field: "product_type",
        title: "Product Type",
        description: "Select if this item is a physical good or a service you're offering.",
        footer: "Note",
        footer_content: "Once you include this items in a transaction, you cannot change its type."
      }]
    };
  },
  watch: {
    barcodeData: function barcodeData(param) {
      this.barcode = param;
    },
    productCreateData: {
      deep: true,
      handler: function handler(param) {
        var _this2 = this;

        this.productCreate = param;

        if (param && this.lodash.isEmpty(param.warranty)) {
          this.warrantyActive = true;
          this.productCreate.warranty.push({
            id: null,
            value: null,
            field: null
          });
        }

        this.$nextTick(function () {
          if (param.warranty && param.warranty.length) {
            var item = compact(map(param.warranty, "id"));

            if (item && item.length) {
              _this2.warrantyActive = true;
            }
          }
        });
      }
    },
    productCreate: {
      deep: true,
      handler: function handler() {
        var _this = this;

        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.$emit("saveProduct", _this.productCreate);
        }, _this.timeoutLimit);
      }
    },
    "productCreate.product_type": function productCreateProduct_type() {
      this.getOptions();
    }
  },
  methods: {
    updateImages: function updateImages(param) {
      this.productCreate.images = param;
    },
    pushWarranty: function pushWarranty() {
      this.productCreate.warranty.push({
        id: null,
        value: null,
        field: null
      });
    },
    removeWarranty: function removeWarranty(index, id) {
      var _this = this;

      if (id && id > 0) {
        _this.$store.dispatch(DELETE, {
          url: "product/" + _this.productId + "/entity",
          data: {
            type: 1,
            entities: [id]
          }
        }).then(function () {
          _this.productCreate.warranty.splice(index, 1);
        }).catch(function (error) {
          _this.logError(error);
        });
      } else {
        _this.productCreate.warranty.splice(index, 1);
      }
    },
    getOptions: function getOptions() {
      var _this = this;

      _this.$store.dispatch(QUERY, {
        url: "product/options",
        data: {
          product_type: _this.productCreate.product_type
        }
      }).then(function (response) {
        if (_this.lodash.isEmpty(response.data) === false && response.data.brand) {
          _this.brandList = response.data.brand;
        }

        if (_this.lodash.isEmpty(response.data) === false && response.data.category) {
          _this.categoryList = response.data.category;
        }

        if (_this.lodash.isEmpty(response.data) === false && response.data.manufacturer) {
          _this.manufacturerList = response.data.manufacturer;
        }

        if (_this.lodash.isEmpty(response.data) === false && response.data.supplier) {
          _this.supplierList = response.data.supplier;
        }

        if (_this.lodash.isEmpty(response.data) === false && response.data.tax) {
          _this.taxList = response.data.tax;

          if (!_this.productCreate.tax) {
            var index = _this.lodash.findIndex(response.data.tax, function (tax) {
              return tax.default;
            });

            if (index >= 0) {
              _this.productCreate.tax = _this.taxList[index].id;
            }
          }
        }

        if (_this.lodash.isEmpty(response.data) === false && response.data.taxExemption) {
          _this.taxExemptionList = response.data.taxExemption;

          if (!_this.productCreate.tax_exemption) {
            var _index = _this.lodash.findIndex(response.data.taxExemption, function (taxExemption) {
              return taxExemption.default;
            });

            if (_index >= 0) {
              _this.productCreate.tax_exemption = _this.taxExemptionList[_index].id;
            }
          }
        }

        if (_this.lodash.isEmpty(response.data) === false && response.data.unit) {
          _this.unitList = response.data.unit;
        }

        if (!_this.updateMode) {
          if (!_this.productId && _this.lodash.isEmpty(response.data) === false && response.data.barcode) {
            _this.barcode = response.data.barcode;
          }

          if (_this.lodash.isEmpty(response.data.barcode_setting) === false) {
            _this.barcodeSetting = response.data.barcode_setting;
            _this.productCreate.description = _this.barcodeSetting.description;
          }
        }

        _this.productCreate.serial_number = response.data.serial_number;
      }).catch(function (error) {
        _this.$store.dispatch(UPDATE_ERROR, error);
      });
    }
  },
  mounted: function mounted() {
    this.getOptions();
  }
};