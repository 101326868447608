import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Dialog from "@/view/pages/partials/Dialog.vue";
import { PATCH } from "@/core/services/store/request.module";
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false
    },
    manufacturer: {
      type: Array,
      default: function _default() {
        return new Array();
      }
    }
  },
  watch: {
    manufacturer: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.manufacturerList = param;
      }
    }
  },
  data: function data() {
    return {
      pageLoading: false,
      manufacturerList: [{
        text: null
      }]
    };
  },
  methods: {
    updateProductManufacturer: function updateProductManufacturer() {
      var _this = this;

      _this.pageLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "setting/product-manufacturer",
        data: {
          product_manufacturer: _this.manufacturerList
        }
      }).then(function () {
        _this.$emit("close-dialog", true);

        _this.$emit("get-product-manufacturer", true);
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      });
    },
    createProductManufacturer: function createProductManufacturer() {
      this.manufacturerList.push({
        text: null
      });
    },
    deleteProductManufacturer: function deleteProductManufacturer(index) {
      if (this.manufacturerList.length > 1) {
        this.manufacturerList.splice(index, 1);
      }
    }
  },
  components: {
    Dialog: Dialog
  },
  computed: {
    dialogWidth: function dialogWidth() {
      return this.lodash.toSafeInteger(document.body.clientWidth / 100 * 50);
    }
  }
};